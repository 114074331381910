<template lang="pug">
    .main-wrapper.empresa-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "empresas" }'> Empresas</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            DialogUsuario(
                v-if="usuario.dialog"
                :visible="usuario.dialog"
                :model="usuario.model"
                :cd_empresa="model.id"
                @close="usuario.dialog = false"
                @saved="getUsuariosEmpresa()")

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Empresa`")
                    .p-grid.p-fluid.p-align-end
                        .p-col-12.ta-right(v-if="model.cd_pessoa_juridica.id")
                            a.link(@click="$router.push(`/pessoa-juridica/salvar/${model.cd_pessoa_juridica.id}/`)") Ir para Pessoa Juridica
                        .p-col-12.p-md-2
                            ProgressBar(v-if="waitingTipo" mode="indeterminate")
                            div(v-else)
                                label.form-label Tipo Pessoa Juridica:
                                Dropdown(
                                    v-model='model.cd_pessoa_juridica.ie_tipo_pessoa_juridica'
                                    :options='options.tipos'
                                    optionLabel='ds_valor'
                                    optionValue='ie_valor'
                                    placeholder='Selecione'
                                )
                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.nr_cnpj.$error }")
                            label.form-label CNPJ:
                            InputMask(mask='99.999.999/9999-99' v-model='$v.model.cd_pessoa_juridica.nr_cnpj.$model')
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.nr_cnpj.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.ds_razao_social.$error }")
                            label.form-label Razão Social:
                            InputText(type='text' v-model='$v.model.cd_pessoa_juridica.ds_razao_social.$model')
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.ds_razao_social.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.ds_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.ds_razao_social.required") <b>Razão Social</b> é obrigatório.


                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.nm_fantasia.$error }")
                            label.form-label Nome fantasia:
                            InputText(type='text' v-model='$v.model.cd_pessoa_juridica.nm_fantasia.$model')
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.nm_fantasia.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                        .p-col-12.p-md-2
                            ProgressBar(v-if="waitingTipoEmpresa" mode="indeterminate")
                            div(v-else)
                                label.form-label Tipo de Empresa:
                                Dropdown(
                                    v-model='model.cd_tipo_empresa'
                                    :options='options.tiposEmpresa'
                                    optionLabel='text'
                                    optionValue='value'
                                    placeholder='Selecione'
                                )

                        .p-col-12.p-md-2
                            label.form-label IE:
                            InputText(type='text' v-model='model.cd_pessoa_juridica.nr_inscricao_estadual')

                        .p-col-12.p-md-2
                            label.form-label IM:
                            InputText(type='text' v-model='model.cd_pessoa_juridica.nr_inscricao_municipal')

                        .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.planos_contas.$error }")
                            ProgressBar(v-if="waitingPlanoContas" mode="indeterminate")
                            div(v-else)
                                label.form-label Conta Financeira:
                                MultiSelect(
                                    :options='options.contasFinanceiras'
                                    placeholder='Selecione'
                                    optionLabel='ds_descricao'
                                    dataKey='id'
                                    :filter='true'
                                    v-model='model.cd_pessoa_juridica.planos_contas'
                                )
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.planos_contas.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.planos_contas.required") <b>Plano Contas</b> é obrigatório.

                        .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.cnaes.$error }")
                            ProgressBar(v-if="waitingCnae" mode="indeterminate")
                            div(v-else)
                                label.form-label Código CNAE:
                                MultiSelect(
                                    :options='options.cnaes'
                                    placeholder='Selecione'
                                    optionLabel='ds_descricao'
                                    dataKey='cod_cnae'
                                    :filter='true'
                                    v-model='model.cd_pessoa_juridica.cnaes'
                                )
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.cnaes.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.cnaes.required") <b>CNAE</b> é obrigatório.

                        .p-col-12.p-md-2
                            label.form-label Emitir NF Automaticamente:
                            Checkbox(v-model='model.cd_pessoa_juridica.ie_nf_automatico' :binary="true")
                    div(v-if="!model.id")
                        Panel(header="Endereço")
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.enderecos.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.enderecos.required") <b>Endereço</b> é obrigatório.
                            br
                            Endereco(:parentData="model.cd_pessoa_juridica.enderecos[0]" :pessoaJuridicaId="model.id" @enderecoEmitido="handleEndereco" ref="enderecoComponent")
                        br
                        Panel(header="Contatos")
                            Contato(:parentData="model.cd_pessoa_juridica.contatos" :pessoaJuridicaId="model.id" @contatosEmitido="handleContatos" ref="contatoComponent")
                        br
                        Panel(header="Dados Bancários")
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.bancos.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.bancos.required") <b>Ao menos um banco</b> é obrigatório.
                                br
                            DadosBancarios(:parentData="model.cd_pessoa_juridica.bancos" :pessoaJuridicaId="model.id" @bancosEmitido="handleBancos" ref="dadosBancariosComponent")
                        br
                        Panel(header="Impostos")
                            Impostos(:parentData="model.cd_pessoa_juridica.tributos" @tributosEmitidos="handleTributos" ref="tributosComponent")

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                        br
                        .p-grid
                            .p-col-12.p-md-6.p-lg-3
                                span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                            .p-col-12.p-md-6.p-lg-3
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6.p-lg-3
                                span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                            .p-col-12.p-md-6.p-lg-3
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}
                    .p-col-12.ta-right
                        Button(label='Salvar' icon='jam jam-check' type="submit" style='max-width:160px')

            Panel.datatable.mt-3(header="Usuários" v-if="model.id")

                ProgressBar(v-if='usuario.waiting || waiting' mode="indeterminate")

                div(v-else-if="!usuarios.length")
                    p.ta-center Nenhum usuário cadastrado!

                .ta-center(v-else)
                    DataTable(:value="usuarios")
                        Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                            template(#body='props')
                                ProgressSpinner.waiting-status(v-if='usuario.statusIds.includes(props.data.id)' strokeWidth='6')
                                .status-indicator(
                                    v-else :class="{ off: ! props.data.is_active }"
                                    @click='alterarStatusUsuario(props.data)')
                                    i.jam(:class="{ 'jam-check': props.data.is_active, 'jam-minus': !props.data.is_active }")
                        Column(headerStyle='width: 35%;' bodyStyle='text-align: left;' field='full_name' header='Nome')
                        Column(headerStyle='width: 25%;' field='username' header='Usuário')
                        Column(headerStyle='width: 25%;' field='email' header='Email')
                        Column(headerStyle='width: 10%;' header="Ações")
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="onpenDialogUsuario(props.data)"
                                    )

                .p-grid.p-fluid.p-align-end.mt-2
                    .p-col-12.ta-right
                        Button(
                            label='Adicionar'
                            icon='jam jam-plus'
                            style='max-width: 160px'
                            :disabled='usuario.waiting'
                            @click="onpenDialogUsuario()")

            Panel.datatable.mt-3(
                :header="`Lista de ${model.cd_tipo_empresa === 1 ? 'pacientes' : 'associados'}`"
                v-if="model.id")

                .p-grid.p-fluid.p-align-end
                    .p-col-8
                        label.form-label Nome:
                        InputText(type='text' v-model='filters.nm_pessoa_fisica' @keyup.enter="filtrarAssociados()")

                    .p-col-4
                        label.form-label CPF:
                        InputMask(
                            :mask="'999.999.999-99'"
                            v-model="filters.nr_cpf"
                            @keydown.enter="filtrarAssociados()"
                            :autoClear="false")

                    .p-col-12.ta-right
                        Button(
                            label='Filtrar'
                            icon='jam jam-search'
                            style='max-width: 160px'
                            :disabled='waitingAssociados'
                            @click="filtrarAssociados()")

                ProgressBar(v-if='waitingAssociados || waiting' mode="indeterminate")

                div(v-else-if="!listaAssociados.length")
                    p.ta-center Nenhum item encontrado

                div(v-else)
                    Paginator.mb-1(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")
                    DataTable(:value="listaAssociados")
                        Column(headerStyle='width: 25%;' field='nm_pessoa_fisica' header='Nome')
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF')
                        Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='dt_nascimento_f' header='Data de nascimento')
                        Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
                        Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='nr_telefone_f' header='Telefone')
                        Column(headerStyle='width: 18%;' header='E-mail')
                            template(#body='props')
                                span(v-tooltip.top="props.data.ds_email" style='cursor: pointer;') {{ props.data.ds_email_f }}
                        Column(headerStyle='width: 8%; text-align: center')
                            template(#header) Ações
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="$router.push(`/paciente/salvar/${ props.data.id }/`)"
                                    )
                    Paginator(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")

</template>

<style lang="scss">
    .empresa-salvar {
        .link {
            cursor: pointer
        }
        .waitingEmpresas-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .waiting-status{
            width: 25px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Paginator from 'primevue/paginator'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import BuscarPaciente from '../Paciente/BuscarPaciente'
    import InputSwitch from 'primevue/inputswitch';
    import { Empresa } from './../../middleware'
    import {Utils} from "@/middleware"
    import wsConfigs from './../../middleware/configs'
    import { fixTelefoneFormat } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'
    import DialogUsuario from "@/components/Empresa/DialogUsuario"
    import Checkbox from 'primevue/checkbox'
    import MultiSelect from 'primevue/multiselect'
    import Endereco from '../PessoaJuridica/Components/Endereco.vue'
    import DadosBancarios from '../PessoaJuridica/Components/DadosBancarios.vue'
    import Impostos from '../PessoaJuridica/Components/Impostos.vue'
    import Contato from '../PessoaJuridica/Components/Contato.vue'

    import { PessoaJuridica, DominioValor as Dominio, PlanoContas } from '../../middleware'

    const _ = require('lodash');

    export default {
        components: {
            DialogUsuario,
            ProgressBar, Panel, InputText, Button, Password, ProgressSpinner, Impostos, Contato,
                InputMask, SelectButton, Dropdown, Tooltip, PickList, DataTable, Column,
                Paginator, Dialog, BuscarPaciente, InputSwitch, Checkbox, MultiSelect, Endereco, DadosBancarios,
        },
        directives: { tooltip: Tooltip },
        created () {

            let id = parseInt(this.$route.params.id)
            if (! isNaN(id)) {
                this.getEmpresa(id)

                if (!id) {
                    this.getTiposEmpresa()
                    this.getPlanoContas()
                    this.getTipos()
                    this.getCnaes()
                }
            }
        },
        data () {
            return {
                model: {
                    ds_tipo_empresa: null,
                    ds_motivo_suspensao: null,
                    cd_pessoa_juridica: {
                        id: null,
                        nr_cnpj: null,
                        nm_fantasia: null,
                        nr_inscricao_estadual: null,
                        nr_inscricao_municipal: null,
                        ie_tipo_pessoa_juridica: null,
                        ds_observacao: null,
                        dt_atualizado: null,
                        nm_usuario_edi: null,
                        ds_razao_social: null,
                        enderecos: [],
                        contatos: [],
                        bancos: [],
                        tributos: [],
                        planos_contas: [],
                        cnaes: [],
                    },
                    ds_observacao: null,
                    nm_responsavel: null,
                    cd_tipo_empresa: null,
                },
                options: {
                    especialidades: [],
                    procedimentos: [],
                    tiposEmpresa: [],
                    ufs: [],
                    cidades: [],
                },
                filters: {
                    nm_pessoa_fisica: '',
                    nr_cpf: ''
                },
                waiting: false,
                waitingForm: false,
                waitingAssociados: false,
                usuario: {
                    waiting: false,
                    statusIds: [],
                    dialog: false,
                    model: {}
                },
                waitingTipoEmpresa: false,
                waitingEstado: false,
                waitingCidade: false,
                submitted: false,
                usuarios: [],
                listaAssociados: [],
                paginatorAssociados: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                params: {},
                getAssociadosDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true })
            }
        },
        validations () {
            let v = {
                model: {
                    cd_pessoa_juridica: {
                        ds_razao_social: { required, minLength: minLength(2) },
                        nm_fantasia: { required, minLength: minLength(2) },
                        nr_cnpj: { required, minLength: minLength(2) },
                        enderecos: { required },
                        bancos: { required },
                        planos_contas: { required },
                        cnaes: { required },
                    },
                    // nm_responsavel: { required, minLength: minLength(2) },

                }
            }
            return v
        },
        methods: {
            onpenDialogUsuario(model) {
                this.usuario.model = Object.assign({}, model)
                this.usuario.dialog = true
            },
            _getEndereco() {
                if (this.model?.cd_pessoa_juridica?.enderecos?.length) {
                    return this.model.cd_pessoa_juridica.enderecos[0]
                }
                return {
                    nr_cep: null,
                    nm_logradouro: null,
                    nr_numero: null,
                    ds_complemento: null,
                    nm_bairro: null,
                }
            },
            _getContato() {
                if (this.model?.cd_pessoa_juridica?.contatos?.length) {
                    return this.model.cd_pessoa_juridica.contatos[0]
                }
                return {
                    nr_telefone: null,
                    nm_contato: null,
                    nr_celular: null,
                    nr_whatsapp: null,
                    ds_email: null,
                }
            },
            getEmpresa(id) {
                if (!id) return
                this.waiting = true
                Empresa.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => this.model[key] = response.data[key])
                        this.model.id = id
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (response.data.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                        }
                        if (this.model.cd_estado) {
                            this.getCidades()
                            this.model.cd_cidade = response.data.cd_cidade
                        }
                        this.filtrarAssociados()
                        this.getUsuariosEmpresa()
                    }
                    this.getTiposEmpresa()
                    this.getPlanoContas()
                    this.getTipos()
                    this.getCnaes()
                    this.waiting = false
                })
            },
            getAssociados(params = {}){
                params.cd_empresa = this.model.id
                this.waitingAssociados = true
                Empresa.listarAssociados(params).then( response => {
                    if (response.status === 200) {
                        response.data.results.forEach(paciente => {
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            if (paciente.ds_email) {
                                let strMaxLength = 24
                                paciente.ds_email_f = paciente.ds_email.length > strMaxLength ?
                                    `${ paciente.ds_email.substr(0, strMaxLength) }...` : paciente.ds_email
                            }
                        })
                        this.listaAssociados = response.data.results
                        this.paginatorAssociados.count = response.data.count
                    }
                    this.waitingAssociados = false
                })
            },
            getTipos() {
                this.waitingTipo = true
                Dominio.findAll({ ds_mnemonico: 'TIPO_PESSOA_JURIDICA' }).then((response) => {
                    if (response.status === 200) {
                        this.options.tipos = response.data['TIPO_PESSOA_JURIDICA'].valores
                    }
                    this.waitingTipo = false
                })
            },
            getPlanoContas() {
                this.waitingPlanoContas = true
                PlanoContas.findAll().then(response => {
                    if ([200, 201].includes(response.status)) {
                        this.options.contasFinanceiras = response.data
                        let new_plano_contas = []

                        if (this.model.cd_pessoa_juridica.planos_contas) {
                            this.model.cd_pessoa_juridica.planos_contas.forEach(conta => {
                                for (let option of this.options.contasFinanceiras) {
                                    if (conta.cd_plano_contas && conta.cd_plano_contas == option.id) {
                                        new_plano_contas.push(option)
                                        this.model.cd_pessoa_juridica.planos_contas = new_plano_contas
                                        break
                                    }
                                }
                            })
                        }
                    }
                    this.waitingPlanoContas = false
                })
            },
            getCnaes() {
                this.waitingCnae = true
                PessoaJuridica.findAllCnae().then(response => {
                    if ([200, 201].includes(response.status)) {
                        this.options.cnaes = response.data.map(cnae => {
                            return {
                                ds_descricao: cnae.ds_descricao,
                                cod_cnae: cnae.id
                            }
                        })

                        let new_cnae = []
                        if (this.model.cd_pessoa_juridica.cnaes) {
                            this.model.cd_pessoa_juridica.cnaes.forEach(cnae => {
                                for (let option of this.options.cnaes) {
                                    if (cnae.cod_cnae && cnae.cod_cnae == option.cod_cnae) {
                                        new_cnae.push(option)
                                        this.model.cd_pessoa_juridica.cnaes = new_cnae
                                        break
                                    }
                                }
                            })
                        }
                    }
                    this.waitingCnae = false
                })
            },
            handleEndereco(endereco) {
                this.model.cd_pessoa_juridica.enderecos = endereco
                this.$forceUpdate()
            },
            handleContatos(contatos) {
                this.model.cd_pessoa_juridica.contatos = contatos
                this.$forceUpdate()
            },
            handleBancos(bancos) {
                this.model.cd_pessoa_juridica.bancos = bancos
                this.$forceUpdate()
            },
            handleTributos(tributos) {
                this.model.cd_pessoa_juridica.tributos = tributos
                this.$forceUpdate()
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) {
                    return 0
                }
                let dataSend = _.cloneDeep(this.model)
                dataSend.cd_pessoa_juridica.nr_cnpj = dataSend.cd_pessoa_juridica.nr_cnpj.replace(/\D/g, "")
                if (dataSend.cd_pessoa_juridica.planos_contas.length){
                    dataSend.cd_pessoa_juridica.lista_planos_contas = dataSend.cd_pessoa_juridica.planos_contas.map(element => element.id)
                    dataSend.cd_pessoa_juridica.planos_contas = dataSend.cd_pessoa_juridica.planos_contas.map(element => element.id)
                }
                if (dataSend.cd_pessoa_juridica.cnaes.length) {
                    dataSend.cd_pessoa_juridica.lista_cnae = dataSend.cd_pessoa_juridica.cnaes.map(cnae => cnae.cod_cnae)
                }
                if (dataSend.cd_pessoa_juridica.bancos.length) {
                    dataSend.cd_pessoa_juridica.bancos.map(element => {
                        element.cd_banco = element.cd_banco.id
                        if (element.ds_tipo_pix) {
                            element.ds_tipo_pix = element.ds_tipo_pix.value
                        }
                        return element
                    })
                }

                this.waitingForm = true
                Empresa.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'empresas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            getEstados() {
                this.waitingEstado = true
                Utils.getUFs().then(response => {
                    this.waitingEstado = false
                    this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(uf => {
                        this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                    })
                })
            },
            getCidades() {
                this.model.cd_cidade = null
                this.options.cidades = []
                if (! this.model.cd_estado) return

                this.waitingCidade = true
                Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                    this.waitingCidade = false
                    this.options.cidades = [{ value: null, text: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(cidade => {
                        this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                    })
                })
            },
            getTiposEmpresa() {
                this.waitingTipoEmpresa = true
                Empresa.getTiposEmpresa().then(response => {
                    this.waitingTipoEmpresa = false
                    if (response.status === 200) {
                        this.options.tiposEmpresa = response.data.map(tipo => {
                            return { value: tipo.id, text: tipo.ds_tipo_empresa }
                        })
                    }
                })
            },
            getUsuariosEmpresa() {
                this.usuario.waiting = true
                Empresa.listRevendedores({ cd_empresa: this.model.id }).then(response => {
                    this.usuario.waiting = false
                    if (response.status === 200) {
                        this.usuarios = response.data.map(usuario => {
                            return {
                                full_name: `${usuario.first_name} ${usuario.last_name}`,
                                ...usuario
                            }
                        })
                    }
                })

            },
            alterarStatusUsuario(usuario) {
                const dataSend = { id: usuario.id, is_active: !usuario.is_active }
                this.usuario.statusIds.push(usuario.id)
                Empresa.saveUsuario(dataSend).then(response => {
                    this.usuario.statusIds = this.usuario.statusIds.filter(x => x !== usuario.id)
                    if (response.status === 200) {
                        usuario.is_active = !usuario.is_active
                    }
                })
            },
            filtrarAssociados(page) {
                this.paginatorAssociados.page = page || 1
                let params = {
                    paginacao: true,
                    page: this.paginatorAssociados.page,
                    per_page: this.paginatorAssociados.per_page
                }
                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                        else params[key] = this.filters[key]
                    }
                })
                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params)) {
                    if (!this.waitingAssociados) {
                        this.waitingAssociados = true
                        setTimeout(() => this.waitingAssociados = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waitingAssociados = true
                    this.getAssociadosDebounce(params, this.getAssociados)
                }
            },
            onPageAssociados(ev) {
                this.filtrarAssociados(ev.page + 1)
            },
        }
    }
</script>
